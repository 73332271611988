<template>
  <div class="pl10 pr10">
    <el-form v-model="callBackMessages" label-width="110px" size="small">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="SourceTrackLink:">
            <el-input v-model="callBackMessages.sourceTrackLink"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label="Country:">
            <!-- <el-select v-model="callBackMessages.country" filterable clearable class="w100">
              <el-option
                v-for="item in countryOptions"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select> -->
            <el-input v-model="callBackMessages.country"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label="Ip:">
            <el-input v-model="callBackMessages.ip"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label="Ua:">
            <el-input v-model="callBackMessages.ua"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label="Gaid:">
            <el-input v-model="callBackMessages.gaid"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label="idfa:">
            <el-input v-model="callBackMessages.idfa"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label-width="70px">
            <el-button type="primary" @click="trackLinkTest">Run Test</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div style="width: 50%; word-wrap: break-word; margin-left: 20px;">
      <span style="font-size: 14px; font-weight: 500;">response_content: </span>
      <span>{{ result && Object.keys(result).length ? result.response_content : '' }}</span>
    </div>
    <br />
    <div style="width: 50%; word-wrap: break-word; margin-left: 20px;">
      <span style="font-size: 14px; font-weight: 500;">through_link:</span>
      <div
        v-for="(item, key) in result && Object.keys(result).length ? result.through_urls : []"
        :key="item"
      >
        <div style="margin-top: 5px;">
          <span
            v-if="result.code == 0 && key == result.through_urls.length - 1"
            style="background-color: green; width: 12px; height: 12px; border-radius: 50%; display: inline-block;"
          ></span>
          {{ item }}
        </div>
      </div>
    </div>
    <br />
    <div style="width: 50%; word-wrap: break-word; margin-left: 20px; min-height: 200px;">
      <span style="font-size: 14px; font-weight: 500;">click_url:</span>
      <div>{{ result && Object.keys(result).length ? result.click_url : '' }}</div>
    </div>
    <el-row>
      <div
        class="showmsg"
        style="margin-left:40px; font-size:16px;line-height:1.5em"
        v-html="showmsg"
      ></div>
    </el-row>
  </div>
</template>

<script>
  import TrackLinkTestCon from '../controllers/integration/TrackLinkTest';
  export default {
    name: 'TrackLinkTest',
    ...TrackLinkTestCon,
  };
</script>

<style></style>
