import { getMacklink, linkTest } from 'api/callback';
import optionData from '@/assets/js/optionData';
export default {
  data() {
    return {
      sourcecallBack: {
        offerId: '',
      },
      checkOfferTest: {
        requestUrl: '',
        platform: 'android',
        country: 'us',
      },
      platformOptions: [
        {
          label: 'Android',
          value: 'android',
        },
        {
          label: 'IOS',
          value: 'ios',
        },
      ],
      countryOptions: optionData.countryOptions,
      submitData: [],
      sumbitOfferdisbled: false,
      jumpResult: {
        status: '',
        detail: '',
      },
    };
  },
  methods: {
    handleSelectChange(val) {
      this.sourceIdArray = [];
      for (var i = 0; i < this.sourcesList.length; i++) {
        for (var j = 0; j < this.sourcesList[i].children.length; j++) {
          if (this.sourcesList[i].children[j].value == val) {
            this.type = this.sourcesList[i].label;
          }
        }
      }

      for (let i = 0; i < val.length; i++) {
        for (let j = 0; j < JSON.parse(val[i]).length; j++) {
          this.sourceIdArray.push(JSON.parse(val[i])[j]);
        }
      }
    },
    makeTestLinkClick() {
      const offerId = this.sourcecallBack.offerId.trim();
      if (!offerId) {
        this.$message.warning('do not choose offerId!');
        return;
      }

      getMacklink(offerId).then((response) => {
        if (response.code === 200) {
          this.checkOfferTest.requestUrl = response.result.requestUrl;
        } else {
          this.$message.error('error:' + response.message);
        }
      });
    },
    sumbitOfferClick() {
      this.sumbitOfferdisbled = true;
      this.$message({
        message: '正在加载中，请稍候...',
        type: 'warning',
      });
      const param = {
        ...this.checkOfferTest,
      };
      linkTest(param)
        .then((response) => {
          const result = response.result;
          this.jumpResult.status = result.status;
          this.jumpResult.detail = result.domains;
          if (response.code !== 200) {
            this.$message.error(response.message);
          }
        })
        .finally(() => {
          this.sumbitOfferdisbled = false;
        });
    },
    fillImages(images) {
      var html = '';
      if (images != null) {
        images.split(',').forEach((item, i) => {
          html += "<div style='float:left;'><img src='" + item + "' width='50' height='50' /> ";
          if (i < images.split(',').length - 1) {
            html += "<i class='el-icon-right' style='font-size:20px'></i>";
          }
          html += '</div>';
        });
      }
      return html;
    },
    fillDomains(domains) {
      var html = '';
      if (domains != null) {
        domains.split(',').forEach((item, i) => {
          html +=
            '<span style="word-wrap:break-word;word-break:break-all;white-space: normal">' +
            item +
            '</span>';
          if (i < domains.split(',').length - 1) {
            html += "<i class='el-icon-right' style='font-size:20px'></i>";
          }
        });
      }
      return html;
    },
  },
};
