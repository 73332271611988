<template>
  <div>
    <el-form v-model="sourcecallBack" label-width="80px" size="small">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label="OfferId:">
            <el-input v-model="sourcecallBack.offerId" placeholder="offerId here"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label-width="0px">
            <el-button type="primary" @click="makeTestLinkClick">Make TestLink</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form :model="checkOfferTest" label-width="80px" size="small">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :md="10" :lg="10" :xl="10">
          <el-form-item label="TestLink:">
            <el-input v-model="checkOfferTest.requestUrl" placeholder="link here"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label="Platform:">
            <el-select v-model="checkOfferTest.platform" filterable clearable class="w100">
              <el-option
                v-for="item in platformOptions"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label="Country:">
            <el-select v-model="checkOfferTest.country" filterable clearable class="w100">
              <el-option
                v-for="item in countryOptions"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="2" :lg="2" :xl="2">
          <el-form-item label-width="0px">
            <el-button type="primary" @click="sumbitOfferClick" :disabled="sumbitOfferdisbled"
              >SUBMIT</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-card class="card">
      <p class="font-14 mb10">
        跳转状态：
        <span v-if="jumpResult.status === 1">跳转成功</span>
        <span v-else-if="jumpResult.status === 0">跳转失败</span>
        <span v-else-if="jumpResult.status !== ''">跳转异常</span>
      </p>
      <p class="font-14"> 跳转详情：<span v-html="fillDomains(jumpResult.detail)"></span></p>
    </el-card>
  </div>
</template>

<script>
  import SourceCallbackTestCon from '../controllers/integration/SourceCallbackTest';
  export default {
    name: 'SourceCallbackTest',
    ...SourceCallbackTestCon,
  };
</script>

<style lang="scss">
  .card.el-card {
    border: none;
  }
</style>
