import { getOfferTestResult, updateOfferTestResult } from 'api/callback';
export default {
  data() {
    return {
      checkAffiliTest: {
        offerId: null,
      },
      testClickDisabled: false,
      listLoading: false,
      affiliateTestList: [],
      throughUrls: [],
      response_content: '',
      click_url: '',
    };
  },
  methods: {
    getStatus(httpCode) {
      switch (httpCode) {
        case '200':
          return 'success';
        case '302':
          return 'finish';
        case '301':
          return 'finish';
        case '307':
          return 'finish';
        case '303':
          return 'finish';
        case '500':
          return 'error';
        default:
          return 'error';
      }
    },

    stepsSize() {
      if (this.throughUrls && this.throughUrls.length > 0) {
        return this.throughUrls.length + 1;
      } else {
        return 0;
      }
    },

    sumbitClick() {
      this.listLoading = true;
      const param = {
        ...this.checkAffiliTest,
      };
      this.throughUrls = [];
      this.response_content = '';
      this.click_url = '';
      this.testClickDisabled = true;
      getOfferTestResult(param)
        .then((response) => {
          this.listLoading = false;
          this.testClickDisabled = false;
          if (response.code === 200) {
            if (response.result.code && response.result.code != '0' && response.result.msg) {
              this.$message.error(response.result.msg);
            }
            if (response.result.throughUrls) {
              this.throughUrls = response.result.throughUrls;
            }
            if (response.result.response_content) {
              this.response_content = response.result.response_content;
            }
            this.click_url = response.result.click_url;
          } else {
            this.$message.error(response.message);
          }
        })
        .catch((error) => {
          this.testClickDisabled = false;
          this.$message.error(error);
        });
    },
    fillImages(images) {
      var html = '';
      if (images != null) {
        images.split(',').forEach((item, i) => {
          html += "<div style='float:left;'><img src='" + item + "' width='50' height='50' /> ";
          if (i < images.split(',').length - 1) {
            html += "<i class='el-icon-right' style='font-size:20px'></i>";
          }
          html += '</div>';
        });
      }
      return html;
    },
    fillDomains(domains) {
      var html = '';
      if (domains != null) {
        domains.split(',').forEach((item, i) => {
          html += "<div style='float:left;'><span>" + item + '</span>';
          if (i < domains.split(',').length - 1) {
            html += "<i class='el-icon-right' style='font-size:20px'></i>";
          }
          html += '</div>';
        });
      }
      return html;
    },
    updateClick(row) {
      const offerId = row.offerId;
      updateOfferTestResult(offerId).then((response) => {
        if (response.code === 200) {
          this.$message({
            message: 'Update Success',
            type: 'success',
          });
          this.affiliateTestList = [response.result];
        } else {
          this.$message.error('Update Error:' + response.message);
        }
      });
    },
  },
};
