import GroupSelect from '@/components/GroupSelect';

import optionData from '@/assets/js/optionData';
import { affiliateTest } from 'api/callback';
import { mapState, mapActions } from 'vuex';
export default {
  components: {
    GroupSelect,
  },
  props: {
    affLoading: {
      type: Boolean,
      default: true,
    },
    affGroupList: {
      type: Array,
      default: () => [],
    },
    affiliates: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('affiliate', {
      affInfoList: (state) => state.affiliates,
    }),
  },
  data() {
    return {
      callBackMessages: {
        affiliates: null,
        postBackUrl: null,
        requestUrl: null,
        platform: null,
        country: null,
      },
      platformOptions: [
        {
          label: 'Android',
          value: 'android',
        },
        {
          label: 'IOS',
          value: 'IOS',
        },
      ],
      countryOptions: optionData.countryOptions,
      showmsg: null,
    };
  },
  methods: {
    ...mapActions('affiliate', ['getAffiliateInfoList']),
    affiliateTest() {
      const param = {
        postBackUrl: this.callBackMessages.postBackUrl,
        requestUrl: this.callBackMessages.requestUrl,
        platform: this.callBackMessages.platform,
        country: this.callBackMessages.country,
      };
      affiliateTest(param).then((response) => {
        if (response.code === 200) {
          const result = response.result;
          const { body = '', success = '', postback = '' } = result;
          this.showmsg = `body:${body}</br>success:${success}</br>postback:${postback}`;
        } else {
          this.$message.error(response.message);
        }
      });
    },
    handleSelectChange(value) {
      for (const item of this.affInfoList) {
        if (item.id === value) {
          this.callBackMessages.postBackUrl = item.postbackUrl ? item.postbackUrl : '';
          break;
        }
      }
    },
  },
  mounted() {
    this.getAffiliateInfoList();
  },
};
