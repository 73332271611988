import GroupSelect from '@/components/GroupSelect';

import optionData from '@/assets/js/optionData';
import { trackLinkTest } from 'api/callback';
import { mapState, mapActions } from 'vuex';
export default {
  components: {
    GroupSelect,
  },
  props: {
    affLoading: {
      type: Boolean,
      default: true,
    },
    affGroupList: {
      type: Array,
      default: () => [],
    },
    affiliates: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('affiliate', {
      affInfoList: (state) => state.affiliates,
    }),
  },
  data() {
    return {
      callBackMessages: {
        affiliates: null,
        postBackUrl: null,
        requestUrl: null,
        platform: null,
        country: null,
      },
      platformOptions: [
        {
          label: 'Android',
          value: 'android',
        },
        {
          label: 'IOS',
          value: 'IOS',
        },
      ],
      countryOptions: optionData.countryOptions,
      showmsg: null,
      result: [],
    };
  },
  methods: {
    ...mapActions('affiliate', ['getAffiliateInfoList']),
    trackLinkTest() {
      const param = {
        tracking_link: this.callBackMessages.sourceTrackLink,
        ip_address: this.callBackMessages.ip,
        ua: this.callBackMessages.ua,
        country: this.callBackMessages.country,
        gaid: this.callBackMessages.gaid,
        idfa: this.callBackMessages.idfa,
      };
      trackLinkTest(Object.assign({}, param)).then((response) => {
        if (response.msg === 'success') {
          this.result = response;
        } else {
          this.$message.error(response.msg);
          this.result = response;
        }
      });
    },
    handleSelectChange(value) {
      for (const item of this.affInfoList) {
        if (item.id === value) {
          this.callBackMessages.postBackUrl = item.postbackUrl ? item.postbackUrl : '';
          break;
        }
      }
    },
  },
  mounted() {
    this.getAffiliateInfoList();
  },
};
