<template>
  <div class="mainBox">
    <el-form :model="checkAffiliTest" label-width="70px" size="small">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="20" :md="6" :lg="6" :xl="6">
          <el-form-item label="OfferId:">
            <el-input v-model="checkAffiliTest.offerId" placeholder="offerId here"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="4" :md="6" :lg="6" :xl="6">
          <el-form-item label-width="0px">
            <el-button type="primary" :loading="testClickDisabled" @click="sumbitClick"
              >SUBMIT</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!--    <el-table
      v-loading="listLoading"
      stripe
      border
      :data="affiliateTestList"
      highlight-current-row
      class="w100"
      size="mini"
    >
      <el-table-column label="OfferId" prop="offerId"></el-table-column>
      <el-table-column label="Status" prop="status" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == 0" type="danger" align="center">跳转失败</el-tag>
          <el-tag v-else-if="scope.row.status == 1" type="success" align="center">跳转成功</el-tag>
          <el-tag v-else type="info">跳转异常</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="Redirects Number"
        prop="redirectsNum"
        align="center"
      ></el-table-column>
      <el-table-column label="Test Number" prop="testNum" align="center"></el-table-column>
      <el-table-column label="Tracking Domain" align="center">
        <template slot-scope="scope">
          <div v-html="fillDomains(scope.row.domains)"></div>
        </template>
      </el-table-column>

      <el-table-column label="Update Time">
        <template slot-scope="scope">
          <span>{{ scope.updateTime ? scope.updateTime : scope.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Option" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="updateClick(scope.row)">Update</el-button>
        </template>
      </el-table-column>
    </el-table>-->

    <!--    <div style="width: 50%; word-wrap: break-word; margin-left: 20px;">
      <span style="font-size: 14px; font-weight: 500;">response_content: </span>
      <span>{{ result && Object.keys(result).length ? result.response_content : '' }}</span>
    </div>
    <br />
    <div style="width: 50%; word-wrap: break-word; margin-left: 20px;">
      <span style="font-size: 14px; font-weight: 500;">through_link:</span>
      <div
        v-for="(item, key) in result && Object.keys(result).length ? result.throughUrls : []"
        :key="item"
      >
        <div style="margin-top: 5px;">
          <span
            v-if="result.code == 0 && key == result.throughUrls.length - 1"
            style="background-color: green; width: 12px; height: 12px; border-radius: 50%; display: inline-block;"
          ></span>
          {{ item }}
        </div>
      </div>
    </div>
    <br />
    <div style="width: 50%; word-wrap: break-word; margin-left: 20px; min-height: 200px;">
      <span style="font-size: 14px; font-weight: 500;">click_url:</span>
      <div>{{ result && Object.keys(result).length ? result.click_url : '' }}</div>
    </div>-->

    <div style="min-height: 250px; word-wrap: break-word;">
      <el-steps direction="vertical" :active="stepsSize()">
        <el-step
          v-for="(step, index) in throughUrls"
          :key="index"
          :title="step.httpCode"
          :description="step.url"
          :status="getStatus(step.httpCode)"
        >
          ></el-step
        >
        <br />
      </el-steps>
    </div>
    <el-divider></el-divider>
    <div style="width: 50%; word-wrap: break-word; margin-left: 20px; min-height: 100px;">
      <span style="font-size: 16px; color: #409eff; font-weight: 500;">click_url: </span>
      <span style="color: #409eff;">{{ click_url ? click_url : '' }}</span>
    </div>
    <div style="width: 50%; word-wrap: break-word; margin-left: 20px;">
      <span style="font-size: 16px; color: #409eff; font-weight: 500;">response_content: </span>
      <span style="color: #409eff;">{{ response_content ? response_content : '' }}</span>
    </div>
    <br />
  </div>
</template>

<script>
  import OfferTestCon from '../controllers/integration/OfferTest';
  export default {
    name: 'OfferTest',
    ...OfferTestCon,
  };
</script>

<style></style>
