<template>
  <div class="pl10 pr10">
    <el-form v-model="callBackMessages" label-width="80px" size="small">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label="Affiliate(s):">
            <group-select
              v-model="callBackMessages.affiliates"
              :data-source="affGroupList"
              :loading="affLoading"
              clearable
              filterable
              class="w100"
              @change="handleSelectChange"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label="PostBack:">
            <el-input v-model="callBackMessages.postBackUrl" placeholder="postback here"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label="TestLink:">
            <el-input v-model="callBackMessages.requestUrl" placeholder="testlink here"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label="Platform:">
            <el-select v-model="callBackMessages.platform" filterable clearable class="w100">
              <el-option
                v-for="item in platformOptions"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label="Country:">
            <el-select v-model="callBackMessages.country" filterable clearable class="w100">
              <el-option
                v-for="item in countryOptions"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <el-form-item label-width="0px">
            <el-button type="primary" @click="affiliateTest">Callback</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <div
        class="showmsg"
        style="margin-left:40px; font-size:16px;line-height:3em"
        v-html="showmsg"
      ></div>
    </el-row>
  </div>
</template>

<script>
  import AffCallbackTestCon from '../controllers/integration/AffCallbackTest';
  export default {
    name: 'AffCallbackTest',
    ...AffCallbackTestCon,
  };
</script>

<style></style>
