<template>
  <div class="mainBox">
    <el-form label-width="100px" size="small">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="20" :md="6" :lg="6" :xl="6">
          <el-form-item label="Source" label-width="74px">
            <el-select
              v-model="resultSourceId"
              :loading="sourceLoading"
              clearable
              filterable
              class="w100"
            >
              <el-option-group v-for="group in sourcesList" :key="group.label" :label="group.label">
                <el-option
                  v-for="item in group.children"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="4" :md="6" :lg="6" :xl="6">
          <el-form-item label-width="0px">
            <el-button type="primary" :disabled="offerResultDisabled" @click="offerTestResult"
              >Search</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      border
      stripe
      :data="dataList"
      v-loading="resultLoading"
      highlight-current-row
      class="w100"
      size="mini"
    >
      <el-table-column label="Count" prop="count" align="center"></el-table-column>
      <el-table-column label="Status" prop="status" align="center">
        <template slot-scope="scope" align="center">
          <el-tag v-if="scope.row.status == 0 || scope.row.status == -1" type="danger"
            >跳转失败</el-tag
          >
          <el-tag v-else-if="scope.row.status == '1'" type="success">跳转成功</el-tag>
          <el-tag v-else type="info">暂未测试</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Action" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == null && scope.row.status == undefined"
            type="info"
            @click="delOfferTestResultClick(scope.row)"
            disabled
            >Delete</el-button
          >
          <el-button v-else type="primary" @click="delOfferTestResultClick(scope.row)"
            >Delete</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 删除提示框 -->
    <el-dialog
      title="Delete"
      :visible.sync="delOfferTestResultVisible"
      width="300px"
      append-to-body
    >
      <div class="del-dialog-cnt"
        >Confirm to <span> SourceId: {{ resultSourceId }} </span> delete ?</div
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="delOfferTestResultVisible = false">cancel</el-button>
        <el-button type="primary" @click="delOfferTestResultRow">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import OfferTestResultCon from '../controllers/integration/OfferTestResult';

  export default {
    name: 'OfferTestResult',
    ...OfferTestResultCon,
  };
</script>

<style></style>
