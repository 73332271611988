import { getSourceTestResult, deleteOfferTest } from 'api/callback';

export default {
  props: {
    sourceLoading: {
      type: Boolean,
      default: false,
    },
    sourcesList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      resultSourceId: null,
      offerResultDisabled: false,
      dataList: [],
      resultLoading: false,
      delOfferTestResultVisible: false,
    };
  },
  methods: {
    offerTestResult() {
      this.resultLoading = true;
      this.offerResultDisabled = true;
      if (this.resultSourceId !== null && this.resultSourceId !== '') {
        getSourceTestResult(this.resultSourceId).then((response) => {
          this.resultLoading = false;
          this.offerResultDisabled = false;
          if (response.code === 200) {
            this.dataList = response.result;
          } else {
            this.$message.error(response.message);
          }
        });
      } else {
        this.$message.error('请输入SourceId再试');
        this.resultLoading = false;
        this.offerResultDisabled = false;
      }
    },
    delOfferTestResultClick(row) {
      this.delOfferTestResultVisible = true;
      this.delStatus = row.status;
    },
    delOfferTestResultRow() {
      deleteOfferTest(this.resultSourceId, this.delStatus).then((response) => {
        if (response.code === 200) {
          this.$message({
            message: 'Delete Success',
            type: 'success',
          });
          this.offerTestResult();
        } else {
          this.$message.error('Delete Error:' + response.message);
        }
      });
      this.delOfferTestResultVisible = false;
      this.delStatus = null;
    },
  },
};
